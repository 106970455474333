<template>
  <div class="font-14 rounded-sm bg-300 d-flex align-center justify-between p-s mb-xs cursor-pointer">
    <div><div class="mr-s d-inline"><slot></slot></div>{{ label }}</div>
    <div
      class="font-14 color-150">{{ value }}
      <ArrowIcon class="ml-s" :orientation="0" width="16" height="16" color="#e6e1f3" />
    </div>

  </div>
</template>

<script>
import ArrowIcon from '@/components/icons/ArrowIcon.vue';

export default {
  name: 'InventoryStatsRow',
  components: {
    ArrowIcon
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>