<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M8.00016 14.6673C7.07794 14.6673 6.21127 14.4923 5.40016 14.1423C4.58905 13.7923 3.8835 13.3173 3.2835 12.7173C2.6835 12.1173 2.2085 11.4118 1.8585 10.6007C1.5085 9.78954 1.3335 8.92287 1.3335 8.00065C1.3335 7.07843 1.5085 6.21176 1.8585 5.40065C2.2085 4.58954 2.6835 3.88398 3.2835 3.28398C3.8835 2.68398 4.58905 2.20898 5.40016 1.85898C6.21127 1.50898 7.07794 1.33398 8.00016 1.33398C8.92239 1.33398 9.78905 1.50898 10.6002 1.85898C11.4113 2.20898 12.1168 2.68398 12.7168 3.28398C13.3168 3.88398 13.7918 4.58954 14.1418 5.40065C14.4918 6.21176 14.6668 7.07843 14.6668 8.00065C14.6668 8.92287 14.4918 9.78954 14.1418 10.6007C13.7918 11.4118 13.3168 12.1173 12.7168 12.7173C12.1168 13.3173 11.4113 13.7923 10.6002 14.1423C9.78905 14.4923 8.92239 14.6673 8.00016 14.6673ZM7.3335 8.66732V4.66732H8.66683V8.66732H7.3335ZM8.47516 11.1423C8.34738 11.2701 8.18905 11.334 8.00016 11.334C7.81127 11.334 7.65294 11.2701 7.52516 11.1423C7.39739 11.0145 7.3335 10.8562 7.3335 10.6673C7.3335 10.4784 7.39739 10.3201 7.52516 10.1923C7.65294 10.0645 7.81127 10.0007 8.00016 10.0007C8.18905 10.0007 8.34738 10.0645 8.47516 10.1923C8.60294 10.3201 8.66683 10.4784 8.66683 10.6673C8.66683 10.8562 8.60294 11.0145 8.47516 11.1423ZM11.7835 11.784C10.7502 12.8173 9.48905 13.334 8.00016 13.334C6.51127 13.334 5.25016 12.8173 4.21683 11.784C3.1835 10.7507 2.66683 9.48954 2.66683 8.00065C2.66683 6.51176 3.1835 5.25065 4.21683 4.21732C5.25016 3.18398 6.51127 2.66732 8.00016 2.66732C9.48905 2.66732 10.7502 3.18398 11.7835 4.21732C12.8168 5.25065 13.3335 6.51176 13.3335 8.00065C13.3335 9.48954 12.8168 10.7507 11.7835 11.784Z"
      fill="#FFC107" />
  </svg>

</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>