<template>
  <div class="mb-m">
    <CreateWinesModal ref="createWinesModal" @addedNewVenueWines="handleAddedNewVenueWines" />

    <div v-if="loading">
      <LoadingAnimation />
    </div>
    <div v-if="!loading" class="viewContainer inventory">
      <div>
        <h1>{{ $t('inventory.inventories') }} / {{ inventory.name }}</h1>
        <SubmitInventoryModal :inventoryVenueWineState="inventoryVenueWineState" :inventoryId="inventory.id" @inventorySubmitted="handleInventorySubmitted" />
        <SyncInventoryModal :inventoryVenueWineState="inventoryVenueWineState" :inventoryId="inventory.id" @inventorySynced="handleInventorySynced" />
      </div>
      <div class="head mb-m">
        <div class="left">
          <BackButton />
          <div class="middle">
            <div class="font-32 color-100 w-400 flex-row align-center justify-start gap-xs">
              <div>{{ inventory.name }}</div>
              <InventoryState :inventoryState="inventory.inventoryState" />
            </div>
            <p class="font-14 color-150 w-400">{{ $t(`inventory.${inventory.inventoryType}`) }} | {{ new
              Date(inventory.startDate).toLocaleDateString() }}</p>
          </div>
        </div>
        <Button v-if="isInventoryOnly" class="w-m" :onClick="handleSubmit" :buttonText="$t('inventory.submit')"
          :disabled="inventory.inventoryState === 'uploaded' || inventory.inventoryState === 'finished'"
          :primary="true">
          <template v-slot:svg>
            <SubmitIcon />
          </template>
        </Button>
        <Button v-if="!isInventoryOnly && inventory.inventoryState !== 'finished'" class="w-m"
          :onClick="handleSyncInventory" :buttonText="$t('inventory.finish')" :disabled="disableSyncButton"
          :primary="true">
        </Button>

        <Button v-if="!isInventoryOnly && inventory.inventoryState === 'finished'" class="w-m" :onClick="createReport"
          :buttonText="$t('inventory.createReport')" :primary="false">
          <template v-slot:svg>
            <DownloadIcon />
          </template>
        </Button>



      </div>
      
      <div>
        <Statuscards :inventoryVenueWineState="inventoryVenueWineState"/>
     
        <Performance class="mb-l" :inventoryVenueWineState="inventoryVenueWineState" />
        <div class="products mb-l">
          <h3 class="font-18 font-700 color-100">{{ $t('inventory.allProducts') }}</h3>
          <div @click="showInventoryWines('uncheckedWines')">
            <InventoryStatsRow :label="$t('inventory.unchecked')" :value="inventoryVenueWineState.totalWines -
              inventoryVenueWineState.checkedWines.total" />
          </div>
          <div @click="showInventoryWines('checkedWines')">

            <InventoryStatsRow :label="$t('inventory.checked')" :value="inventoryVenueWineState.checkedWines.total" />
          </div>
          <button :disabled="inventory.inventoryState === 'finished'" @click="addWine()" class="iconButton add">{{
            $t('inventory.addProduct')
          }}</button>


        </div>
        <div class="performance-optimization mb-l">
          <h3 class="font-18 font-700 color-100">{{ $t('inventory.performanceOptimization') }}</h3>
          <p class="color-150 font-14 mb-s">{{ $t('inventory.performanceHint') }}</p>
          <div @click="showInventoryWines('divergentStock')">
            <InventoryStatsRow :label="$t('inventory.divergentStock')" :value="inventoryVenueWineState.divergentStock">
              <DivergentIcon />
            </InventoryStatsRow>
          </div>
          <div @click="showInventoryWines('incompleteWines')">

            <InventoryStatsRow :label="$t('inventory.incomplete')"
              :value="inventoryVenueWineState.checkedWines.incomplete">
              <IncompleteIcon />
            </InventoryStatsRow>
          </div>
          <div @click="showInventoryWines('duplicateWines')">
            <InventoryStatsRow :label="$t('inventory.duplicates')" :value="inventoryVenueWineState.duplicateWines">
              <DuplicateIcon width="16" height="16" />
            </InventoryStatsRow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/buttons/Button.vue';
import SubmitIcon from '@/components/icons/SubmitIcon.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import DataService from '@/services/StrapiService.js';
import DataMappingService from '@/services/DataMappingService.js';
import SubmitInventoryModal from '@/components/modals/inventory/SubmitInventoryModal.vue';
import DivergentIcon from '@/components/icons/DivergentIcon.vue';
import InventoryState from '@/components/inventory/InventoryState.vue';
import { filterInventoryVenueWines } from '@/utils/functions';
import { mapGetters } from 'vuex';
import SyncInventoryModal from '@/components/modals/inventory/SyncInventoryModal.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import Performance from '@/components/inventory/Performance.vue';
import { getVenueCurrency } from '@/utils/functions'
import InventoryStatsRow from '@/components/inventory/InventoryStatsRow.vue';
import CreateWinesModal from '@/components/modals/addWine/CreateWinesModal.vue';
import DuplicateIcon from '@/components/icons/DuplicateIcon.vue';
import IncompleteIcon from '@/components/icons/IncompleteIcon.vue';
import Statuscards from '@/components/cards/StatusCards.vue';
import { processVenueWineIds } from '@/utils/functions';

export default {
  name: 'Inventory',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    BackButton,
    Button,
    SubmitIcon,
    SubmitInventoryModal,
    InventoryState,
    SyncInventoryModal,
    LoadingAnimation,
    DownloadIcon,
    Performance,
    InventoryStatsRow,
    CreateWinesModal,
    DivergentIcon,
    DuplicateIcon,
    IncompleteIcon,
    Statuscards

  },
  data() {
    return {
      inventory: {
        id: 0,
        name: '',
        inventoryVenueWines: []
      },
      inventoryVenueWineState: {
        checkedWines: {
          total: 0,
          incomplete: 0,
          complete: 0,
        },
        totalWines: 0,
        actualAmountTotal: 0,
        targetAmountTotal: 0,
        totalValue: 0,
        targetValue: 0,
        actualBottleAmount: 0,
        targetBottleAmount: 0,
        divergentStock: 0,
        selectedWineObject: {},
      },
      loading: false

    }
  },
  created() {
    this.loadInventory(this.$route.params.id);

  },

  methods: {
    addWine() {
      this.$refs.createWinesModal.showModal();
    },
    handleAddedNewVenueWines(venueWineIds) {
      processVenueWineIds(
        venueWineIds,
        this.inventory.id,
        this.loadInventory,
        this.$route,
        this.$store,
        this.$t
      );
      this.loadInventory(this.$route.params.id);
    },
    createReport() {
      console.log('createReport')
    },
    handleInventorySynced() {
      this.loadInventory(this.$route.params.id);
    },
    handleInventorySubmitted() {
      this.loadInventory(this.$route.params.id);
    },
    showInventoryWines(filterString) {
      this.$router.push({ name: 'InventoryWines', params: { id: this.inventory.id, filterString: filterString } });
    },
    getInventoryStats(inventoryVenueWines) {

      const filteredWines = filterInventoryVenueWines(inventoryVenueWines);

      this.inventoryVenueWineState.checkedWines.total = filteredWines.checkedWines.length;
      this.inventoryVenueWineState.checkedWines.incomplete = filteredWines.incompleteWines.length;
      this.inventoryVenueWineState.checkedWines.complete = filteredWines.completeWines.length;
      this.inventoryVenueWineState.totalWines = inventoryVenueWines.length;
      this.inventoryVenueWineState.divergentStock = filteredWines.divergentStock.length;
      this.inventoryVenueWineState.duplicateWines = filteredWines.duplicateWines.length;

      this.inventoryVenueWineState.totalValue = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.actualAmount * wine.sellingPrice, 0);
      this.inventoryVenueWineState.targetValue = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.targetAmount * wine.sellingPrice, 0);
      this.inventoryVenueWineState.actualBottleAmount = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.actualAmount, 0);
      this.inventoryVenueWineState.targetBottleAmount = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.targetAmount, 0);
      this.inventoryVenueWineState.winesToHide = inventoryVenueWines.filter(wine => wine.inventoryVenueWineState === 'unchecked' || wine.actualAmount == 0 || wine.actualAmount == null);

    },

    loadInventory(inventoryId) {
      this.loading = true;
      DataService.getInventory(inventoryId)
        .then(response => {
          this.inventory = DataMappingService.mapGetInventoryResponse(response.data.data);
          this.getInventoryStats(this.inventory.inventoryVenueWines)
        })
        .catch(e => {
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      this.$bvModal.show('submit-inventory-modal')
    },
    handleSyncInventory() {
      this.$bvModal.show('sync-inventory-modal')
    }
  },
  computed: {
    ...mapGetters(['isInventoryOnly', 'getVenue']),
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    disableSyncButton() {
      console.log('this.inventory.inventory_users :>> ', this.inventory);
      return this.inventory.inventoryState !== 'uploaded' && (this.inventory.inventory_users !== null && this.inventory.inventory_users.length > 0)

    },
  }
}
</script>
<style scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  gap: var(--margin-s);
  align-items: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-s);
}

</style>